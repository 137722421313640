<template>
    <section class=" w-full text-base-content">
        <div>{{ $t('pages.theme_admin.configuration') }}</div>
        <div class="flex justify-end mb-6">
        </div>
    </section>
</template>
  
<script>
export default {
    components: {
        //configuration components
    },
    data() {
        return {
            //configuration variables

        }
    },
    methods: {
        //configuration methods
    },

}
</script>
  
<style></style>